global.reportHandler = {}

reportHandler.setReportAssessmentImageAsActive = function(reportAssessment) {
  var sectionId = $(reportAssessment).data('section-id');

    $(".report-assessment-images").removeClass('active');
    $(reportAssessment).addClass('active');

    $('#' + sectionId).siblings('.assessment-content-block').addClass('hidden');
    $('#' + sectionId).removeClass("hidden");
}

reportHandler.showReportTabs = function() {
  $(".report-assessment-images").on('click', function(){
    reportHandler.setReportAssessmentImageAsActive(this);
    commonHandler.handleReadMoreTextBlock();
  });

  $(".report-assessment-images:first").trigger('click');
}

setEventListeners(document, ["DOMContentLoaded", "turbo:render"], function(e) {
  // Personality Feedback
  $('.report-content').on('click', '.trait-bar', function(){
    $(this).closest('.traits-chart').find('.trait-bar').removeClass('active');
    $(this).addClass('active');
  });

  $('.report-content .trait-bar:eq(0)').trigger('click');

  // Interview coaching
  reportHandler.showReportTabs();

  var upgradePopupContent = $('#assessment-upgrade-facebox');

  if (upgradePopupContent.length) {
    $.facebox(upgradePopupContent.html(), 'hide-close', { overlay: false, close: false });
  }
});
